import React from "react"
import Seo from "@root/Seo"
import Footer from "@root/Footer"
import loadable from "@loadable/component"
import BackgroundCodes from "@elements/BackgroundCodes"
import { graphql } from "gatsby"

export default function PageTemplate({ data }) {
  const page = data.main.edges[0].node
  const { seo } = page
  const HomeIntro = () => {
    if (page.slug === "home") {
      const WpAcfHeaderIntro = loadable(() =>
        import("@modules/WpAcfHeaderIntro")
      )
      return <WpAcfHeaderIntro />
    }
  }
  const GutenbergComponent = () => {
    if (
      page.template.templateName === "Gutenberg Content" &&
      page.content !== null
    ) {
      const GutenbergContent = loadable(() =>
        import("@modules/GutenbergContent")
      )
      return <GutenbergContent content={page.content} title={page.title} />
    }
  }
  return (
    <>
      <Seo
        title={`${seo.title || page.title}`}
        description={`${seo.metaDesc || ""}`}
      />
      {page.slug === "home" && <HomeIntro />}
      {page.template.templateName === "Gutenberg Content" &&
        page.content !== null && <GutenbergComponent />}
      {page.blocks.map((keyName, i) => {
        if (keyName.__typename.includes("WpCore")) return
        if (
          keyName.__typename.includes("Ref") &&
          !keyName.__typename.includes("ReferenzenArchive")
        )
          return

        const LoadableModuleBlock = loadable(() =>
          import(`@modules/${keyName.__typename}`)
        )
        return (
          <LoadableModuleBlock
            moduleData={keyName}
            key={`${keyName.__typename}-${i}`}
            isHome={page.slug === "home" ? true : false}
          />
        )
      })}
      <BackgroundCodes />
      <Footer footerOptions={page.acfFooterOptions} />
    </>
  )
}

export const query = graphql`
  query ($page: String) {
    main: allWpJob(filter: { id: { eq: $page } }) {
      edges {
        node {
          title
          content
          uri
          slug
          databaseId
          id
          content
          template {
            templateName
          }
          seo {
            metaDesc
            title
          }
          acfFooterOptions {
            footerLayout
          }
          blocks {
            __typename
            ... on WpAcfReferenzenArchiveBlock {
              attributes {
                anchor
                className
                id
              }
            }
            ... on WpAcfContactModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfContactBlock {
                backgroundColor
                button {
                  target
                  title
                  url
                }
                description
                headline
                startSymbol
                endSymbol
                symbolsColor
                textColor
              }
            }
            ... on WpAcfTypeWritingModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfTypeWritingBlock {
                sectionButton {
                  target
                  title
                  url
                }
                sectionOverline
                step2Description
                step3Description
                step3Symbol
                step4Description
                step5Description
                step2Image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                step4Image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 350
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                step5Image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                step5Video {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpAcfStepCardsModuleBlock {
              acfStepCardsBlock {
                cards {
                  description
                  fieldGroupName
                  headline
                  startSymbol
                  endSymbol
                  stepSymbol
                }
                introHeadline
                startSymbol
                endSymbol
                introSubline
              }
              attributes {
                anchor
                className
                id
              }
            }
            ... on WpAcfAccordionModuleBlock {
              acfAccordionBlock {
                headline
                items {
                  description
                  title
                }
              }
              attributes {
                anchor
                className
                id
              }
            }
            ... on WpAcfTextTeasersModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfTextTeasersBlock {
                headline
                topSpacing
                bottomSpacing
                items {
                  description
                  title
                  trimWords
                }
              }
            }
            ... on WpAcfSubheaderModuleBlock {
              acfSubHeaderBlock {
                button {
                  target
                  title
                  url
                }
                copy
                endSymbol
                headline
                startSymbol
                doubleImage
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                secondImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 600
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
              attributes {
                anchor
                className
                id
              }
            }
            ... on WpAcfCarouselModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfCarouselBlock {
                slides {
                  slideSize
                  image1Name
                  image2Name
                  image1NameDarkColor
                  image2NameDarkColor
                  image1 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 700
                          quality: 60
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  image1Link {
                    target
                    title
                    url
                  }
                  image2 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 700
                          quality: 60
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  image2Link {
                    target
                    title
                    url
                  }
                }
              }
            }
            ... on WpAcfCardsModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfCardsBlock {
                cards {
                  backgroundColor
                  description
                  descriptionColor
                  headline
                  startSymbol
                  endSymbol
                  headlineColor
                  size
                  symbolsColor
                  type
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          quality: 50
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  badge {
                    mimeType
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfSmallCardsModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfSmallCardsBlock {
                headline
                startSymbol
                endSymbol
                backgroundColor
                headlineColor
                accordion
                cards {
                  cardHeadline
                  cardDescription
                  cardBackground
                  headlineColor
                  descriptionColor
                }
              }
            }
            ... on WpAcfTextImageModuleBlock {
              attributes {
                anchor
                className
                id
              }
              acfTextImageBlock {
                contentColumnPosition
                backgroundColor
                descriptionColor
                headlineColor
                sublineColor
                horizontalAlign
                verticalAlign
                topSpacing
                bottomSpacing
                contentBlocks {
                  description
                  fieldGroupName
                  headline
                  headingType
                  startSymbol
                  endSymbol
                  subline
                  sublineSmall
                  link {
                    target
                    title
                    url
                  }
                }
                hideOnMobile
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 700
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                secondImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 50
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
